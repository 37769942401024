<template>
  <div>
    <b-navbar class="navbar-main-container" :mobile-burger="false">
      <template slot="brand">
        <b-navbar-item class="navigation-brand">
          <b-button
            size="is-medium"
            icon-left="menu"
            class="navbar-side-menu"
            @click="openSideBar()"
          >
            Menu
          </b-button>

          <div class="buttons">
            <a class="button is-primary" @click="openClientSupportChat()">
              <strong> Soporte al cliente</strong>
            </a>
          </div>
        </b-navbar-item>
      </template>
      <template slot="start">
        <div class="navbar-search-container">
          <b-autocomplete
            placeholder="...Buscar modulo"
            :data="filteredModels"
            v-model="searchResult"
            group-field="type"
            group-options="items"
            autocomplete
            icon="search"
            open-on-focus
            expanded
            clearable
            check-infinite-scroll
            @select="
              (value) => {
                openSelectedSearchOption(value);
                searchResult = '';
              }
            "
          >
          </b-autocomplete>
        </div>
      </template>
      <template slot="end">
        <div class="notifications-container">
          <b-dropdown
            append-to-body
            aria-role="menu"
            scrollable
            max-height="200"
            trap-focus
            position="is-bottom-left"
          >
            <template #trigger>
              <div class="notifications-button">
                <b-taglist attached class="notifications-button-container">
                  <b-tag size="is-medium" type="is-primary" icon="bell-outline">
                    <b-icon icon="bell-outline"> </b-icon
                  ></b-tag>
                  <b-tag size="is-medium" class="notifications-number">{{
                    getUnreadNotifications()
                  }}</b-tag>
                </b-taglist>
              </div>
            </template>

            <b-dropdown-item aria-role="listitem" custom>
              <p class="notifications-title">Notificaciones</p>
              <p
                class="notifications-all-read"
                @click="markAllNotificationAsRead()"
              >
                Marcar todas como leidas
              </p>
              <hr class="single-notification-separator" />
            </b-dropdown-item>

            <b-dropdown-item
              v-for="notification in notifications.reverse()"
              :key="notification._id"
              :value="notification"
              aria-role="listitem"
              custom
            >
              <div class="media single-notification-container">
                <b-icon
                  class="media-left single-notification-status-not-read"
                  v-if="notification.status !== 'C'"
                ></b-icon>
                <b-icon
                  class="media-left single-notification-status-read"
                  v-if="notification.status === 'C'"
                ></b-icon>
                <div class="media-content">
                  <h3 class="single-notification-title">
                    {{ notification.title }}
                  </h3>
                  <small>{{ notification.description }}</small>
                  <br />
                  <small>{{ notification.formattedDate }}</small>
                  <br />
                  <small
                    class="single-notification-mark-read"
                    @click="markNotificationAsRead(notification)"
                    v-if="notification.status !== 'C'"
                    >Marcar como leida</small
                  >
                  <hr />
                </div>
              </div>
            </b-dropdown-item>
          </b-dropdown>
        </div>

        <b-navbar-item tag="div">
          <div class="buttons">
            <a class="button is-primary" @click="confirmLogout()">
              <strong>Cerrar sesión</strong>
            </a>
          </div>
        </b-navbar-item>
      </template>
    </b-navbar>
    <b-sidebar
      type="is-light"
      @close="closeSideBar()"
      :fullheight="true"
      :overlay="true"
      :open.sync="sideBarOpen"
    >
      <div>
        <b-menu class="navbar-side-menu-inside-menu" v-if="permissions">
          <b-menu-list
            label="General"
            class="navbar-side-menu-inside-menu-menu"
          >
            <b-menu-item
              :active="activeNav === 'home'"
              icon="home"
              label="Inicio"
              @click="openInitialDashboard()"
            ></b-menu-item>

            <b-menu-item
              :active="activeNav === 'analytcisChat'"
              icon="chat"
              label="Chat inteligente"
              @click="openAnalyticsChat()"
            ></b-menu-item>

            <b-menu-item
              :active="activeNav === 'analytics'"
              icon="chart-bar"
              v-if="
                permissions &&
                companyPaymentPlanModules &&
                permissions.main.allAnalytics === 'S' &&
                companyPaymentPlanModules.main.allAnalytics === 'S'
              "
              label="Analisis y reportes"
              @click="openAnalytics()"
            ></b-menu-item>

            <b-menu-item
              :active="activeNav === 'value'"
              icon="domain"
              v-if="
                permissions &&
                companyPaymentPlanModules &&
                permissions.main.calculateCompanyTotalValue === 'S' &&
                companyPaymentPlanModules.main.calculateCompanyTotalValue ===
                  'S'
              "
              label="Valor total de la compañía"
              @click="openCompanyValue()"
            ></b-menu-item>

            <b-menu-item
              :active="activeNav === 'assets'"
              icon="car"
              v-if="
                permissions &&
                companyPaymentPlanModules &&
                permissions.main.getAssets === 'S' &&
                companyPaymentPlanModules.main.getAssets === 'S'
              "
              label="Activos de la compañía"
              @click="openCompanyAssets()"
            ></b-menu-item>
          </b-menu-list>

          <b-menu-list
            label="Productividad"
            class="navbar-side-menu-inside-menu-menu"
            v-if="
              permissions &&
              companyPaymentPlanModules &&
              permissions.productivity.allCalendar === 'S' &&
              companyPaymentPlanModules.productivity.allCalendar === 'S'
            "
          >
            <b-menu-item
              :active="activeNav === 'calendar'"
              id="calendar-option"
              icon="calendar"
              label="Calendario"
              @click="openCalendar()"
            ></b-menu-item>
          </b-menu-list>

          <b-menu-list label="Ventas" class="navbar-side-menu-inside-menu-menu">
            <b-menu-item
              :active="activeNav === 'pos'"
              id="pos-options"
              icon="point-of-sale"
              label="Punto de venta"
              v-if="
                permissions &&
                companyPaymentPlanModules &&
                userInformation.pointOfSale &&
                permissions.sales.allPos === 'S' &&
                companyPaymentPlanModules.sales.allPos === 'S'
              "
              @click="openPointOfSale()"
            ></b-menu-item>
            <b-menu-item
              :active="activeNav === 'clients'"
              id="clients-option"
              v-if="
                permissions &&
                companyPaymentPlanModules &&
                permissions.sales.allClients === 'S' &&
                companyPaymentPlanModules.sales.allClients === 'S'
              "
              icon="account-multiple"
              label="Clientes"
              @click="openClientsLists()"
            ></b-menu-item>
            <b-menu-item
              :active="activeNav === 'sales'"
              id="sales-options"
              v-if="
                permissions &&
                companyPaymentPlanModules &&
                permissions.sales.getSales === 'S' &&
                companyPaymentPlanModules.sales.getSales === 'S'
              "
              icon="currency-usd"
              label="Ventas"
              @click="openSalesLists()"
            ></b-menu-item>
            <b-menu-item
              :active="activeNav === 'professionalServices'"
              id="professionalServices-options"
              v-if="
                permissions &&
                companyPaymentPlanModules &&
                permissions.sales.allProfessionalServices === 'S' &&
                companyPaymentPlanModules.sales.allProfessionalServices === 'S'
              "
              icon="doctor"
              label="Servicios profesionales"
              @click="openProfessionalServicesList()"
            ></b-menu-item>
            <b-menu-item
              :active="activeNav === 'pricesArticlesLists'"
              id="prices-articules-list"
              v-if="
                permissions &&
                companyPaymentPlanModules &&
                permissions.sales.allArticlesPrices === 'S' &&
                companyPaymentPlanModules.sales.allArticlesPrices === 'S'
              "
              icon="calculator"
              label="Precios de artículos"
              @click="openPricesListsArticles()"
            ></b-menu-item>
            <b-menu-item
              :active="activeNav === 'pricesLists'"
              id="prices-list"
              v-if="
                permissions &&
                companyPaymentPlanModules &&
                permissions.sales.allPricesLists === 'S' &&
                companyPaymentPlanModules.sales.allPricesLists === 'S' &&
                permissions.sales.getPriceLists === 'S' &&
                companyPaymentPlanModules.sales.getPriceLists === 'S'
              "
              icon="cash-100"
              label="Listas de precios"
              @click="openPurchasesLists()"
            ></b-menu-item>
            <b-menu-item
              v-if="
                permissions &&
                companyPaymentPlanModules &&
                permissions.sales.getPos === 'S' &&
                companyPaymentPlanModules.sales.getPos === 'S'
              "
              :active="activeNav === 'posCortes'"
              id="sales-options"
              icon="printer-pos"
              label="Cajas y Cortes"
              @click="openAllPosList()"
            ></b-menu-item>
          </b-menu-list>

          <b-menu-list
            label="Transporte"
            class="navbar-side-menu-inside-menu-menu"
          >
            <b-menu-item
              :active="activeNav === 'vehicules'"
              id="vehicules-option"
              v-if="
                permissions &&
                companyPaymentPlanModules &&
                permissions.global.allVehicules === 'S' &&
                companyPaymentPlanModules.global.allVehicules === 'S' &&
                permissions.global.getVehicules === 'S' &&
                companyPaymentPlanModules.global.getVehicules === 'S'
              "
              icon="truck"
              label="Vehículos"
              @click="openVehicules()"
            ></b-menu-item>
            <b-menu-item
              :active="activeNav === 'driversDeliveries'"
              id="vehicules-option"
              v-if="
                permissions &&
                companyPaymentPlanModules &&
                permissions.sales.allDriverMaterialDelivery === 'S' &&
                companyPaymentPlanModules.sales.allDriverMaterialDelivery ===
                  'S'
              "
              icon="truck-delivery"
              label="Envios de material choferes"
              @click="openDriversDeliveries()"
            ></b-menu-item>
            <b-menu-item
              :active="activeNav === 'porteCards'"
              id="vehicules-option"
              v-if="
                permissions &&
                companyPaymentPlanModules &&
                permissions.accounting.savePorteCard === 'S' &&
                companyPaymentPlanModules.accounting.savePorteCard === 'S'
              "
              icon="truck-outline"
              label="Cartas porte"
              @click="openPorteCards()"
            ></b-menu-item>
          </b-menu-list>

          <b-menu-list
            label="
            Inventarios"
            class="navbar-side-menu-inside-menu-menu"
          >
            <b-menu-item
              :active="activeNav === 'salesDeliveries'"
              id="sales-deliveries-options"
              v-if="
                permissions &&
                companyPaymentPlanModules &&
                permissions.sales.getSaleMaterialDeliveries === 'S' &&
                companyPaymentPlanModules.sales.getSaleMaterialDeliveries ===
                  'S'
              "
              icon="cube-send"
              label="Envios de material"
              @click="openMaterialDeliveries()"
            ></b-menu-item>
            <b-menu-item
              :active="activeNav === 'warehouses'"
              id="warehouse-option"
              v-if="
                permissions &&
                companyPaymentPlanModules &&
                permissions.purchase.allWarehouses === 'S' &&
                companyPaymentPlanModules.purchase.allWarehouses === 'S' &&
                permissions.purchase.getWarehouse === 'S' &&
                companyPaymentPlanModules.purchase.getWarehouse === 'S'
              "
              icon="warehouse"
              label="Almacenes"
              @click="openWarehouses()"
            ></b-menu-item>
            <b-menu-item
              :active="activeNav === 'inventory'"
              id="inventory-option"
              v-if="
                permissions &&
                companyPaymentPlanModules &&
                permissions.purchase.allWarehouses === 'S' &&
                companyPaymentPlanModules.purchase.getArticlesInventory === 'S'
              "
              icon="numeric"
              label="Inventario en almacenes"
              @click="openWarehousesArticles()"
            ></b-menu-item>
            <b-menu-item
              :active="activeNav === 'warehousesMovements'"
              id="inventory-movements-option"
              v-if="
                permissions &&
                companyPaymentPlanModules &&
                permissions.purchase.allWarehouses === 'S' &&
                companyPaymentPlanModules.purchase.getWarehouseMovements === 'S'
              "
              icon="file-swap-outline"
              label="Kardex y movimientos en almacenes"
              @click="openWarehouseTranfer()"
            ></b-menu-item>
            <b-menu-item
              :active="activeNav === 'reception'"
              v-if="
                permissions &&
                companyPaymentPlanModules &&
                permissions.purchase.receiveMaterial === 'S' &&
                companyPaymentPlanModules.purchase.receiveMaterial === 'S'
              "
              id="material-reception"
              icon="call-received"
              label="Recepción de material"
              @click="openMaterialReception()"
            ></b-menu-item>
            <b-menu-item
              :active="activeNav === 'transformation'"
              v-if="
                permissions &&
                companyPaymentPlanModules &&
                permissions.global.allTransformations === 'S' &&
                companyPaymentPlanModules.global.allTransformations === 'S'
              "
              id="article-transformation"
              icon="recycle-variant"
              label="Transformación de artículos"
              @click="openArticleTransformation()"
            ></b-menu-item>
          </b-menu-list>

          <b-menu-list
            label="Compras"
            class="navbar-side-menu-inside-menu-menu"
          >
            <b-menu-item
              :active="activeNav === 'purchaseOrders'"
              id="purchase-orders"
              v-if="
                permissions &&
                companyPaymentPlanModules &&
                permissions.purchase.allPurchaseOrders === 'S' &&
                companyPaymentPlanModules.purchase.allPurchaseOrders === 'S' &&
                ((permissions.purchase.getRequestOrders === 'S' &&
                  companyPaymentPlanModules.purchase.getRequestOrders ===
                    'S') ||
                  (permissions.purchase.getPurchaseOrders === 'S' &&
                    companyPaymentPlanModules.purchase.getPurchaseOrders ===
                      'S'))
              "
              icon="receipt"
              label="Ordenes de compra"
              @click="openPurchaseOrders()"
            ></b-menu-item>
            <b-menu-item
              :active="activeNav === 'articles'"
              id="articles-option"
              v-if="
                permissions &&
                companyPaymentPlanModules &&
                permissions.purchase.allArticles === 'S' &&
                companyPaymentPlanModules.purchase.allArticles === 'S' &&
                permissions.purchase.getArticles === 'S' &&
                companyPaymentPlanModules.purchase.getArticles === 'S'
              "
              icon="hammer-screwdriver"
              label="Artículos"
              @click="openPurchasesArticles()"
            ></b-menu-item>
            <b-menu-item
              :active="activeNav === 'prediction'"
              id="prediction-option"
              v-if="
                permissions &&
                companyPaymentPlanModules &&
                permissions.main.makePrediction === 'S' &&
                companyPaymentPlanModules.main.makePrediction === 'S'
              "
              icon="chart-bell-curve-cumulative"
              label="Predicciónes"
              @click="openPurchasesPrediction()"
            ></b-menu-item>
            <b-menu-item
              :active="activeNav === 'providerPrices'"
              id="provider-prices"
              v-if="
                permissions &&
                companyPaymentPlanModules &&
                permissions.purchase.allProviders === 'S' &&
                companyPaymentPlanModules.purchase.allProviders === 'S' &&
                permissions.purchase.saveProvidersPrices === 'S' &&
                companyPaymentPlanModules.purchase.saveProvidersPrices === 'S'
              "
              icon="account-cash"
              label="Relación proveedor artículos"
              @click="openProvidersListsArticles()"
            ></b-menu-item>
            <b-menu-item
              :active="activeNav === 'presentations'"
              v-if="
                permissions &&
                companyPaymentPlanModules &&
                permissions.purchase.allArticles === 'S' &&
                companyPaymentPlanModules.purchase.getArticlesPresentations ===
                  'S'
              "
              icon="screw-lag"
              label="Presentaciones"
              @click="openPresentation()"
            ></b-menu-item>
            <b-menu-item
              :active="activeNav === 'categories'"
              v-if="
                permissions &&
                companyPaymentPlanModules &&
                permissions.purchase.allArticles === 'S' &&
                companyPaymentPlanModules.purchase.allArticles === 'S' &&
                permissions.purchase.getArticles === 'S' &&
                companyPaymentPlanModules.purchase.getArticles === 'S'
              "
              icon="shape"
              label="Familias y Subfamilias"
              @click="openCategories()"
            ></b-menu-item>
            <b-menu-item
              :active="activeNav === 'providers'"
              id="provider-option"
              v-if="
                permissions &&
                companyPaymentPlanModules &&
                permissions.purchase.allProviders === 'S' &&
                companyPaymentPlanModules.purchase.allProviders === 'S' &&
                permissions.purchase.getProviders === 'S' &&
                companyPaymentPlanModules.purchase.getProviders === 'S'
              "
              icon="account-group-outline"
              label="Proveedores"
              @click="openPurchasesProviders()"
            ></b-menu-item>
            <b-menu-item
              :active="activeNav === 'purchasesRevision'"
              id="provider-option"
              v-if="
                permissions &&
                companyPaymentPlanModules &&
                permissions.purchase.allPurchaseRevision === 'S' &&
                companyPaymentPlanModules.purchase.allPurchaseRevision === 'S'
              "
              icon="text-box-check-outline"
              label="Revisión de facturas compras"
              @click="openPurchaseRevision()"
            ></b-menu-item>
          </b-menu-list>

          <b-menu-list
            label="Tesorería"
            class="navbar-side-menu-inside-menu-menu"
          >
            <b-menu-item
              :active="activeNav === 'accounts'"
              id="expenses-icomes-accounts-options"
              v-if="
                permissions &&
                companyPaymentPlanModules &&
                permissions.treasury.allAccounts === 'S' &&
                companyPaymentPlanModules.treasury.allAccounts === 'S'
              "
              icon="account-cash-outline"
              label="Cuentas"
              @click="openTreasuryAccounts()"
            ></b-menu-item>
            <b-menu-item
              :active="activeNav === 'advancedPayments'"
              v-if="
                permissions &&
                companyPaymentPlanModules &&
                permissions.treasury.getAdvancedPayments === 'S' &&
                companyPaymentPlanModules.treasury.getAdvancedPayments === 'S'
              "
              id="expenses-icomes-accounts-options"
              icon="cash"
              label="Anticipos sin cuenta"
              @click="openAdvancedPayments()"
            ></b-menu-item>
            <b-menu-item
              :active="activeNav === 'accountStates'"
              v-if="
                (permissions &&
                  companyPaymentPlanModules &&
                  permissions.accounting.getProvidersBalanceInvoices === 'S' &&
                  companyPaymentPlanModules.accounting
                    .getProvidersBalanceInvoices === 'S') ||
                (permissions &&
                  companyPaymentPlanModules &&
                  permissions.treasury.generateClientBalanceReport === 'S' &&
                  companyPaymentPlanModules.treasury
                    .generateClientBalanceReport === 'S')
              "
              id="clients-providers-accounts-options"
              icon="cash-100"
              label="Estados de cuenta"
              @click="openTreasuryBalances()"
            ></b-menu-item>
          </b-menu-list>

          <b-menu-list
            label="Bancos"
            class="navbar-side-menu-inside-menu-menu"
            v-if="
              permissions &&
              companyPaymentPlanModules &&
              permissions.banks.allAccounts === 'S' &&
              companyPaymentPlanModules.banks.allAccounts === 'S'
            "
          >
            <b-menu-item
              :active="activeNav === 'banks'"
              id="bank-account-options"
              v-if="
                permissions &&
                companyPaymentPlanModules &&
                permissions.banks.allAccounts === 'S' &&
                companyPaymentPlanModules.banks.allAccounts === 'S'
              "
              icon="align-vertical-center"
              label="Cuentas bancarias"
              @click="openBankAllBalances()"
            ></b-menu-item>
          </b-menu-list>

          <b-menu-list
            label="Contabilidad"
            class="navbar-side-menu-inside-menu-menu"
          >
            <b-menu-item
              v-if="
                permissions &&
                companyPaymentPlanModules &&
                permissions.accounting.allAccountingReports === 'S' &&
                companyPaymentPlanModules.accounting.allAccountingReports ===
                  'S'
              "
              :active="activeNav === 'accountingReports'"
              id="sat-reports-option"
              icon="chart-multiple"
              label="Reportes"
              @click="openAccountingReports()"
            ></b-menu-item>
            <b-menu-item
              :active="activeNav === 'contableAccounts'"
              id="sat-coutable-accounts"
              icon="numeric-1-circle"
              label="Cuentas contables y polizas"
              @click="openContableAccounts()"
            ></b-menu-item>
            <b-menu-item
              v-if="
                permissions &&
                companyPaymentPlanModules &&
                permissions.accounting.allSatDocument === 'S'
              "
              :active="activeNav === 'accounting'"
              id="sat-documents-option"
              icon="file-document"
              label="Documentos SAT"
              @click="openSatDocuments()"
            ></b-menu-item>
            <b-menu-item
              :active="activeNav === 'globalInvoices'"
              id="sat-documents-option"
              v-if="
                permissions &&
                companyPaymentPlanModules &&
                permissions.accounting.getDailyInvoices === 'S' &&
                companyPaymentPlanModules.accounting.getDailyInvoices === 'S'
              "
              icon="file-outline"
              label="Facturas globales"
              @click="openGlobalInvoices()"
            ></b-menu-item>
          </b-menu-list>

          <b-menu-list
            label="Recursos humanos"
            class="navbar-side-menu-inside-menu-menu"
          >
            <b-menu-item
              :active="activeNav === 'personal'"
              id="personal-option"
              v-if="
                permissions &&
                companyPaymentPlanModules &&
                permissions.humanResources.allPersonal === 'S' &&
                permissions.humanResources.getPersonal === 'S' &&
                companyPaymentPlanModules.humanResources.allPersonal === 'S' &&
                companyPaymentPlanModules.humanResources.getPersonal === 'S'
              "
              icon="account-group"
              label="Personal"
              @click="openCompanyPersonal()"
            ></b-menu-item>

            <b-menu-item
              :active="activeNav === 'bonds'"
              id="bonds-option"
              v-if="
                permissions &&
                companyPaymentPlanModules &&
                permissions.humanResources.getBonds === 'S' &&
                companyPaymentPlanModules.humanResources.getBonds === 'S'
              "
              icon="swap-vertical"
              label="Bonos"
              @click="openBonds()"
            ></b-menu-item>

            <b-menu-item
              :active="activeNav === 'comissions'"
              id="bonds-option"
              v-if="
                (permissions &&
                  companyPaymentPlanModules &&
                  permissions.humanResources.getPersonalComissions === 'S' &&
                  companyPaymentPlanModules.humanResources
                    .getPersonalComissions === 'S') ||
                (permissions &&
                  companyPaymentPlanModules &&
                  permissions.humanResources.getAllComissions === 'S' &&
                  companyPaymentPlanModules.humanResources.getAllComissions ===
                    'S')
              "
              icon="clipboard-arrow-down-outline"
              label="Comisiones"
              @click="openComissions()"
            ></b-menu-item>
          </b-menu-list>

          <b-menu-list
            label="Configuración"
            class="navbar-side-menu-inside-menu-menu"
          >
            <b-menu-item
              :active="activeNav === 'configuration'"
              id="configuration-option"
              v-if="permissions.global.allConfiguration === 'S'"
              icon="cog"
              label="Configuración"
              @click="openConfiguration()"
            ></b-menu-item>
            <b-menu-item
              id="videos-tutorial-option"
              icon="video-box"
              label="Videos tutoriales"
              @click="openTutorialVideos()"
            ></b-menu-item>
            <b-menu-item
              icon="cast-education"
              label="Repetir tutorial inicial"
              @click="restartTutorial()"
            ></b-menu-item>
            <b-menu-item
              id="configuration-option"
              icon="face-agent"
              label="Soporte al cliente"
              @click="openClientSupport()"
            ></b-menu-item>
            <b-menu-item
              :style="{ display: installBtn }"
              id="configuration-insttall"
              icon="cellphone-arrow-down"
              label="Instalar aplicación"
              @click="installAplication()"
            ></b-menu-item>
            <b-menu-item
              class="navigation-mobile-logout"
              icon="account-cancel"
              label="Cerrar sesión"
              @click="confirmLogout()"
            ></b-menu-item>
          </b-menu-list>
        </b-menu>
      </div>
    </b-sidebar>
  </div>
</template>

<script>
import moment from "moment";
import { EventBus } from "@/event-bus";

// @ is an alias to /src
export default {
  name: "Navbar",
  props: {
    showSearch: {
      type: Boolean,
      default: true,
    },
  },
  components: {},
  data() {
    return {
      name: "",
      selected: null,
      sideBarOpen: false,
      installBtn: "none",
      installer: undefined,
      moment: moment,
      searchableModels: [
        "Inicio",
        "Calendario",
        "Punto de venta",
        "Clientes",
        "Ventas",
        "Envios de material",
        "Precios de artículos",
        "Listas de precios",
        "Cajas y cortes",
        "Vehiculos",
        "Cartas porte",
        "Almacenes",
        "Inventario en almacenes",
        "Kardex y movimientos en almacenes",
        "Recepción de material",
        "Ordenes de compra",
        "Artículos",
        "Predicciones",
        "Relación proveedor artículos",
        "Precios de proveedores",
        "Presentaciones",
        "Familias y subfamilias",
        "Proveedores",
        "Cuentas",
        "Tesoreria",
        "Bancos",
        "Documentos SAT",
        "Personal",
        "Bonos",
        "Comisiones",
        "Configuración",
        "Repetir turorial inicial",
        "Cerrar sesión",
        "Analisis y reportes",
        "Valor total de la compañia",
        "Activos de la compañia",
        "Servicios profesionales",
        "Transformación de artículos",
        "Revisión de facturas compras",
        "Anticipos sin cuenta",
        "Estados de cuenta",
        "Cuentas bancarias",
        "Facturas globales",
        "Reportes contabilidad",
        "Cuentas contables y polizas",
      ],
      searchResult: "",
      installPrompt: null,
    };
  },
  created() {
    window.addEventListener("beforeinstallprompt", (e) => {
      e.preventDefault();
      this.installPrompt = e;
      this.installBtn = "block";
    });
  },
  mounted() {
    EventBus.$on("openNavbar", () => {
      this.openSideBar();
    });
  },
  beforeDestroy() {
    EventBus.$off();
  },
  methods: {
    openSideBar() {
      this.sideBarOpen = true;
    },
    closeSideBar() {
      this.sideBarOpen = false;
    },
    restartUpperHint() {
      this.$store.dispatch("CHANGENEWHINT", "");
    },
    normalizeString(searchString) {
      return searchString
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase();
    },
    openCategories() {
      this.$router.push({ path: "/General/Categories" }).catch(() => {});
      this.closeSideBar();
      this.$store.dispatch(
        "CHANGENEWHINT",
        "En esta pantalla podrás controlar las familias y sub familias usadas en tus artículos, para agregar sub familias es necesario agregar una familia primero"
      );
      this.$store.dispatch("CHANGEACTIVE", "openCategories");
    },
    openPurchasesArticles() {
      this.$router.push({ path: "/Purchases/Articles" }).catch(() => {});
      this.closeSideBar();
      this.$store.dispatch(
        "CHANGENEWHINT",
        "En esta pantalla podrás agregar o importar artículos y marcas a Coconut Control"
      );
      this.$store.dispatch("CHANGEACTIVE", "articles");
    },
    openPurchasesPrediction() {
      this.$router.push({ path: "/Purchases/Prediction" }).catch(() => {});
      this.closeSideBar();
      this.$store.dispatch(
        "CHANGENEWHINT",
        "En esta pantalla podrás generar predicciones de ventas futuras de artículos"
      );
      this.$store.dispatch("CHANGEACTIVE", "prediction");
    },
    openPresentation() {
      this.$router.push({ path: "/Purchases/Presentation" }).catch(() => {});
      this.closeSideBar();
      this.$store.dispatch(
        "CHANGENEWHINT",
        "En esta pantalla podrás controlar las presentaciones de los artículos recuerda llenar a cuantos artículos equivale cada presentación, para poder tener un control mas preciso de tu inventario"
      );
    },
    openPurchasesProviders() {
      this.$router.push({ path: "/Purchases/Providers" }).catch(() => {});
      this.closeSideBar();
      this.$store.dispatch(
        "CHANGENEWHINT",
        "En esta pantalla podrás agregar o importar proveedores a Coconut Control, ademas de consultar el catálogo de giros comerciales"
      );
      this.$store.dispatch("CHANGEACTIVE", "providers");
    },
    openPurchaseRevision() {
      this.$router
        .push({ path: "/Purchases/PurchasesRevision" })
        .catch(() => {});
      this.closeSideBar();
      this.$store.dispatch(
        "CHANGENEWHINT",
        "En esta pantalla podrás editar y autorizar las cuentas por pagar de compras"
      );
      this.$store.dispatch("CHANGEACTIVE", "purchasesRevision");
    },
    openCalendar() {
      this.$router.push({ path: "/Calendar" }).catch(() => {});
      this.closeSideBar();
      this.$store.dispatch(
        "CHANGENEWHINT",
        "En esta pantalla podrás controlar los eventos, recuerda que se puedes elegir enviar recordatorios de tus eventos, ademas de poder cambiar la fecha de estos arrastrandolos a otra fecha en el calendario, también puedes configurar el envio de recordatorios"
      );
      this.$store.dispatch("CHANGEACTIVE", "calendar");
    },
    openBoards() {
      this.$router.push({ path: "/Board" }).catch(() => {});
      this.closeSideBar();
    },
    openPurchasesLists() {
      this.$router.push({ path: "/Sales/PruchasesLists" }).catch(() => {});
      this.closeSideBar();
      this.$store.dispatch(
        "CHANGENEWHINT",
        "En esta pantalla podrás controlar las listas de precios, recuerda llenar el margen de utilidad predeterminado de forma correcta ya que este será utilizado cuando un producto no tenga un margen especifico"
      );
      this.$store.dispatch("CHANGEACTIVE", "pricesLists");
    },
    openPricesListsArticles() {
      this.$router.push({ path: "/Sales/PricesListsArticles" }).catch(() => {});
      this.closeSideBar();
      this.$store.dispatch(
        "CHANGENEWHINT",
        "En esta pantalla podrás controlar los precios de venta de los artículos de acuerdo a las listas de precio, recuerda que el margen predeterminado será utilizado si el producto no cuenta con un margen especifico para alguna lista de precios"
      );
      this.$store.dispatch("CHANGEACTIVE", "pricesArticlesLists");
    },
    openProvidersListsArticles() {
      this.$router
        .push({ path: "/Purchases/ProvidersListsArticles" })
        .catch(() => {});
      this.closeSideBar();
      this.$store.dispatch(
        "CHANGENEWHINT",
        "En esta pantalla podrás realizar pedidos, ordenes de compra y recibir material"
      );
      this.$store.dispatch("CHANGEACTIVE", "providerPrices");
    },
    openPurchaseOrders() {
      this.$router.push({ path: "/Purchases/PurchaseOrders" }).catch(() => {});
      this.closeSideBar();
      this.$store.dispatch(
        "CHANGENEWHINT",
        "En esta pantalla podrás controlar los pedidos y ordenes de compra, recuerda que puedes imprimirlos y enviarlos con o sin precios"
      );
      this.$store.dispatch("CHANGEACTIVE", "purchaseOrders");
    },
    openMaterialReception() {
      this.$router.push({ path: "/Purchases/ReceiveMaterial" }).catch(() => {});
      this.closeSideBar();
      this.$store.dispatch(
        "CHANGENEWHINT",
        "En esta pantalla podrás controlar registrar nuevas recepciones de material de tus ordenes de compra"
      );
      this.$store.dispatch("CHANGEACTIVE", "reception");
    },
    openArticleTransformation() {
      this.$router
        .push({ path: "/General/ArticlesTransformation" })
        .catch(() => {});
      this.closeSideBar();
      this.$store.dispatch(
        "CHANGENEWHINT",
        "En esta pantalla podrás controlar registrar nuevas recepciones de material de tus ordenes de compra"
      );
      this.$store.dispatch("CHANGEACTIVE", "transformation");
    },
    openTreasuryAccounts() {
      this.$router.push({ path: "/treasury/Accounts" }).catch(() => {});
      this.closeSideBar();
      this.$store.dispatch(
        "CHANGENEWHINT",
        "En esta pantalla podrás controlar las cuentas por pagar y cobrar, recuerda que también puedes agregar nuevos pagos, ver el estatus de cada gasto asi como cuanto falta por pagar y estadísticas del saldo actual"
      );
      this.$store.dispatch("CHANGEACTIVE", "accounts");
    },
    openTreasuryBalances() {
      this.$router.push({ path: "/treasury/Balances" }).catch(() => {});
      this.closeSideBar();
      this.$store.dispatch(
        "CHANGENEWHINT",
        "En esta pantalla podrás ver el resumen del estado de cuenta de los proveedores y clientes"
      );
      this.$store.dispatch("CHANGEACTIVE", "accountStates");
    },
    openAdvancedPayments() {
      this.$router.push({ path: "/treasury/AdvancedPayments" }).catch(() => {});
      this.closeSideBar();
      this.$store.dispatch(
        "CHANGENEWHINT",
        "En esta pantalla podrás ver los anticipos sin cuentas por cobrar"
      );
      this.$store.dispatch("CHANGEACTIVE", "advancedPayments");
    },
    openBankAllBalances() {
      this.$router.push({ path: "/Bank/AllBalances" }).catch(() => {});
      this.closeSideBar();
      this.$store.dispatch(
        "CHANGENEWHINT",
        "En esta pantalla podrás controlar las cuentas bancarias, recuerda que puedes conectar tus cuentas bancarias o agregarlas de manera manual, ademas de poder sincronizar movimientos de manera automática o manual. No olvides consolidar tu saldo antes de usarlo"
      );
      this.$store.dispatch("CHANGEACTIVE", "banks");
    },
    openAccountingReports() {
      this.$router.push({ path: "/Accounting/Reports" }).catch(() => {});
      this.closeSideBar();
      this.$store.dispatch(
        "CHANGENEWHINT",
        "En esta pantalla podrás ver distintos reportes del area contable"
      );
      this.$store.dispatch("CHANGEACTIVE", "accountingReports");
    },
    openContableAccounts() {
      this.$router
        .push({ path: "/Accounting/ContableAccounts" })
        .catch(() => {});
      this.closeSideBar();
      this.$store.dispatch(
        "CHANGENEWHINT",
        "En esta pantalla podrás ver las cuentas contables y polizas"
      );
      this.$store.dispatch("CHANGEACTIVE", "contableAccounts");
    },
    openSatDocuments() {
      this.$router.push({ path: "/Accounting/Documents" }).catch(() => {});
      this.closeSideBar();
      this.$store.dispatch(
        "CHANGENEWHINT",
        "En esta pantalla podrás concectar tu cuenta del SAT ver los documentos de Ingreso, Egreso, Pago, Nómina y Traslado que el SAT tiene sobra la compañía"
      );
      this.$store.dispatch("CHANGEACTIVE", "accounting");
    },
    openGlobalInvoices() {
      this.$router.push({ path: "/Accounting/GlobalInvoices" }).catch(() => {});
      this.closeSideBar();
      this.$store.dispatch(
        "CHANGENEWHINT",
        "En esta pantalla podrás ver y administar tus facturas globales"
      );
      this.$store.dispatch("CHANGEACTIVE", "globalInvoices");
    },
    openWarehouses() {
      this.$router.push({ path: "/General/Warehouses" }).catch(() => {});
      this.closeSideBar();
      this.$store.dispatch(
        "CHANGENEWHINT",
        "En esta pantalla podrás controlar los almacenes, recuerda que puedes agregar distintos tipos de almacenes como por ejemplo uno virtual"
      );
    },
    openWarehousesArticles() {
      this.$router
        .push({ path: "/General/WarehousesArticles" })
        .catch(() => {});
      this.closeSideBar();
      this.$store.dispatch(
        "CHANGENEWHINT",
        "En esta pantalla podrás visualizar el inventario de cada artículo en tus almacenes. Agregar nuevo inventario o hacer transferencias entre almacenes"
      );
      this.$store.dispatch("CHANGEACTIVE", "inventory");
    },
    openWarehouseTranfer() {
      this.$router
        .push({ path: "/General/WarehousesTransfers" })
        .catch(() => {});
      this.closeSideBar();
      this.$store.dispatch(
        "CHANGENEWHINT",
        "En esta pantalla podrás ver los movimientos de artículos en tus almacenes (Kardex) o confirmar movimientos pendientes"
      );
      this.$store.dispatch("CHANGEACTIVE", "warehousesMovements");
    },
    openConfiguration() {
      this.$router
        .push({ path: "/Configuration/CompanyConfiguration" })
        .catch(() => {});
      this.closeSideBar();
      this.$store.dispatch(
        "CHANGENEWHINT",
        "En esta pantalla podrás ver y editar la información de la compañía, también podrás conectar nuevas direcciones de correo para enviar mensajes, agregar nuevos usuarios y controlar los perfiles de permisos en el sistema"
      );
      this.$store.dispatch("CHANGEACTIVE", "configuration");
    },
    openTutorialVideos() {
      window
        .open("https://coconutcontrol.tawk.help/category/general", "_blank")
        .focus();
    },
    openClientSupport() {
      window.open(
        "https://tawk.to/chat/6436183d31ebfa0fe7f7c8c4/1gtpktcat",
        "_blank"
      );
    },
    openClientsLists() {
      this.$router.push({ path: "/Sales/ClientsLists" }).catch(() => {});
      this.closeSideBar();
      this.$store.dispatch(
        "CHANGENEWHINT",
        "En esta pantalla podrás controlar prospectos y clientes, ademas de consultar los catalogos de uso del CFDI, regimenes y giros comerciales"
      );
      this.$store.dispatch("CHANGEACTIVE", "clients");
    },
    openSalesLists() {
      this.$router.push({ path: "/Sales/SalesLists" }).catch(() => {});
      this.closeSideBar();
      this.$store.dispatch(
        "CHANGENEWHINT",
        "En esta pantalla podrás controlar las cotizaciones, ventas, conceptos de descuentos y agregar notas de crédito o aclaraciones a las ventas y cotizaciones ya realizadas"
      );
      this.$store.dispatch("CHANGEACTIVE", "sales");
    },
    openProfessionalServicesList() {
      this.$router
        .push({ path: "/Sales/AllProfessionalServices" })
        .catch(() => {});
      this.closeSideBar();
      this.$store.dispatch(
        "CHANGENEWHINT",
        "En esta pantalla podrás controlar las ventas de servicios profesionales"
      );
      this.$store.dispatch("CHANGEACTIVE", "professionalServices");
    },
    openMaterialDeliveries() {
      this.$router.push({ path: "/Sales/SalesDeliveries" }).catch(() => {});
      this.closeSideBar();
      this.$store.dispatch(
        "CHANGENEWHINT",
        "En esta pantalla podrás controlar los envios de material así como poder ver el restante falta de enviar en cada venta"
      );
      this.$store.dispatch("CHANGEACTIVE", "salesDeliveries");
    },
    openPointOfSale() {
      this.$store.dispatch("CHANGEACTIVE", "pos");
      this.$router.push({ path: "/PointOfSale" }).catch(() => {});
      this.closeSideBar();
      this.$store.dispatch(
        "CHANGENEWHINT",
        "En esta pantalla podrás crear nuevas ventas y pagos, recuerda que puedes buscar artículos por clave, descripción o código de barras"
      );
    },
    openInitialDashboard() {
      this.$router.push({ path: "/Dashboards/Initial" }).catch(() => {});
      this.closeSideBar();
      this.$store.dispatch(
        "CHANGENEWHINT",
        "En esta pantalla podrás ver un resumen del status de tu empresa"
      );
      this.$store.dispatch("CHANGEACTIVE", "home");
    },
    openAnalyticsChat() {
      this.$router.push({ path: "/Dashboards/AnalyticsChat" }).catch(() => {});
      this.closeSideBar();
      this.$store.dispatch(
        "CHANGENEWHINT",
        "En esta pantalla podrás usar un agente de inteligencia artificial para obtener información y analisis sobre tu empresa"
      );
      this.$store.dispatch("CHANGEACTIVE", "analytcisChat");
    },
    openAnalytics() {
      this.$router.push({ path: "/Analytics" }).catch(() => {});
      this.closeSideBar();
      this.$store.dispatch(
        "CHANGENEWHINT",
        "En esta pantalla podrás ver analíticos y reportes de tu empresa"
      );
      this.$store.dispatch("CHANGEACTIVE", "analytics");
    },
    openCompanyValue() {
      this.$router.push({ path: "/Dashboards/Value" }).catch(() => {});
      this.closeSideBar();
      this.$store.dispatch(
        "CHANGENEWHINT",
        "En esta pantalla podrás ver un resumen del valor total de tu empresa"
      );
      this.$store.dispatch("CHANGEACTIVE", "value");
    },
    openCompanyAssets() {
      this.$router.push({ path: "/Dashboards/Assets" }).catch(() => {});
      this.closeSideBar();
      this.$store.dispatch(
        "CHANGENEWHINT",
        "En esta pantall podrás administrar los activos de tu compañía"
      );
      this.$store.dispatch("CHANGEACTIVE", "assets");
    },
    restartTutorial() {
      this.$router.push({ path: "/Dashboards/Initial" }).catch(() => {});
      this.closeSideBar();
      this.$store.dispatch(
        "CHANGENEWHINT",
        "En esta pantalla podrás ver un resumen del status de tu empresa"
      );
      this.$tours["initialTour"].start();
    },
    openCompanyPersonal() {
      this.$router.push({ path: "/HumanResources/Personal" }).catch(() => {});
      this.closeSideBar();
      this.$store.dispatch(
        "CHANGENEWHINT",
        "En esta pantalla podrás controlar el personal que hay en la compañía asi como crear un organigrama de la misma"
      );
      this.$store.dispatch("CHANGEACTIVE", "personal");
    },
    openBonds() {
      this.$router.push({ path: "/HumanResources/Bonds" }).catch(() => {});
      this.closeSideBar();
      this.$store.dispatch(
        "CHANGENEWHINT",
        "En esta pantalla podrás controlar los bonos de compra y venta, los bonos tiene mayor prioridad que el procentaje de comisión agregar en el listado de empleados"
      );
      this.$store.dispatch("CHANGEACTIVE", "bonds");
    },
    openComissions() {
      this.$router.push({ path: "/HumanResources/Comissions" }).catch(() => {});
      this.closeSideBar();
      this.$store.dispatch(
        "CHANGENEWHINT",
        "En esta pantalla podrás ver las comisiones de compras y ventas asi como sus detalles"
      );
      this.$store.dispatch("CHANGEACTIVE", "comissions");
    },
    openVehicules() {
      this.$router.push({ path: "/General/Vehicules" }).catch(() => {});
      this.closeSideBar();
      this.$store.dispatch(
        "CHANGENEWHINT",
        "En esta pantalla podrás controlar los vehículos en tu compañía"
      );
      this.$store.dispatch("CHANGEACTIVE", "vehicules");
    },
    openPorteCards() {
      this.$router.push({ path: "/General/PorteCards" }).catch(() => {});
      this.closeSideBar();
      this.$store.dispatch(
        "CHANGENEWHINT",
        "En esta pantalla podrás ver las cartas porte de tu compañía"
      );
      this.$store.dispatch("CHANGEACTIVE", "porteCards");
    },
    openAllPosList() {
      this.$router.push({ path: "/Sales/AllPosList" }).catch(() => {});
      this.closeSideBar();
      this.$store.dispatch(
        "CHANGENEWHINT",
        "En esta pantalla podrás controlar las cajas y cortes en tu compañía, también puedes agregar secretos los cuales permiten realizar acciones en tus puntos de venta"
      );
      this.$store.dispatch("CHANGEACTIVE", "posCortes");
    },
    openDriversDeliveries() {
      this.$router.push({ path: "/Sales/DriversDeliveries" }).catch(() => {});
      this.closeSideBar();
      this.$store.dispatch(
        "CHANGENEWHINT",
        "En esta pantalla podrás controlar y ver los envios que realizados por los choferes"
      );
      this.$store.dispatch("CHANGEACTIVE", "driversDeliveries");
    },
    async logout() {
      this.$store.dispatch("LOGOUT_DATABASE");
      this.$store.dispatch("LOGOUT");
      this.$router.push({ path: "/Login" }).catch(() => {});
    },
    confirmLogout() {
      this.closeSideBar();
      this.$buefy.dialog.confirm({
        message: "¿Desea cerrar sesión?",
        cancelText: "No",
        confirmText: "Si",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => this.logout(),
        onCancel: () => {
          this.searchResult = "";
        },
      });
    },
    installAplication() {
      this.installBtn = "none";
      this.installPrompt.prompt();
    },
    openSelectedSearchOption(value) {
      // Open module or do action depending on search value
      if (value === "Inicio") {
        this.openInitialDashboard();
      }

      if (value === "Chat inteligente") {
        this.openAnalyticsChat();
      }

      if (value === "Analíticos") {
        this.openAnalytics();
      }

      if (value === "Calendario") {
        this.openCalendar();
      }

      if (value === "Punto de venta") {
        this.openPointOfSale();
      }

      if (value === "Clientes") {
        this.openClientsLists();
      }

      if (value === "Ventas") {
        this.openSalesLists();
      }

      if (value === "Envios de material") {
        this.openMaterialDeliveries();
      }

      if (value === "Precios de artículos") {
        this.openPricesListsArticles();
      }

      if (value === "Listas de precios") {
        this.openPurchasesLists();
      }

      if (value === "Cajas y cortes") {
        this.openAllPosList();
      }

      if (value === "Vehiculos") {
        this.openVehicules();
      }

      if (value === "Cartas porte") {
        this.openPorteCards();
      }

      if (value === "Almacenes") {
        this.openWarehouses();
      }

      if (value === "Inventario en almacenes") {
        this.openWarehousesArticles();
      }

      if (value === "Kardex y movimientos en almacenes") {
        this.openWarehouseTranfer();
      }

      if (value === "Recepción de material") {
        this.openMaterialReception();
      }

      if (value === "Transformación de artículos") {
        this.openArticleTransformation();
      }

      if (value === "Ordenes de compra") {
        this.openPurchaseOrders();
      }

      if (value === "Artículos") {
        this.openPurchasesArticles();
      }

      if (value === "Predicciones") {
        this.openPurchasesPrediction();
      }

      if (value === "Relación proveedor artículos") {
        this.openProvidersListsArticles();
      }

      if (value === "Precios de proveedores") {
        this.openProvidersListsArticles();
      }

      if (value === "Presentaciones") {
        this.openPresentation();
      }

      if (value === "Familias y subfamilias") {
        this.openCategories();
      }

      if (value === "Proveedores") {
        this.openPurchasesProviders();
      }

      if (value === "Cuentas") {
        this.openTreasuryAccounts();
      }

      if (value === "Tesoreria") {
        this.openTreasuryAccounts();
      }

      if (value === "Bancos") {
        this.openBankAllBalances();
      }

      if (value === "Documentos SAT") {
        this.openSatDocuments();
      }

      if (value === "Personal") {
        this.openCompanyPersonal();
      }

      if (value === "Bonos") {
        this.openBonds();
      }

      if (value === "Comisiones") {
        this.openComissions();
      }

      if (value === "Configuración") {
        this.openConfiguration();
      }

      if (value === "Repetir turorial inicial") {
        this.restartTutorial();
      }

      if (value === "Cerrar sesión") {
        this.confirmLogout();
      }

      if (value === "Analisis y reportes") {
        this.openAnalytics();
      }

      if (value === "Valor total de la compañia") {
        this.openCompanyValue();
      }

      if (value === "Activos de la compañía") {
        this.openCompanyAssets();
      }

      if (value === "Servicios profesionales") {
        this.openProfessionalServicesList();
      }

      if (value === "Transformación de artículos") {
        this.openArticleTransformation();
      }

      if (value === "Revisión de facturas compras") {
        this.openPurchaseRevision();
      }

      if (value === "Anticipos sin cuenta") {
        this.openAdvancedPayments();
      }

      if (value === "Anticipos sin cuenta") {
        this.openAdvancedPayments();
      }

      if (value === "Estados de cuenta") {
        this.openTreasuryBalances();
      }

      if (value === "Estados de cuenta") {
        this.openTreasuryBalances();
      }

      if (value === "Cuentas bancarias") {
        this.openBankAllBalances();
      }

      if (value === "Facturas globales") {
        this.openGlobalInvoices();
      }

      if (value === "Reportes contabilidad") {
        this.openAccountingReports();
      }

      if (value === "Cuentas contables y polizas") {
        this.openContableAccounts();
      }
    },
    getUnreadNotifications() {
      return this.notifications.reduce((accumulator, notification) => {
        if (notification.status === "P") {
          return accumulator + 1;
        }

        return accumulator;
      }, 0);
    },
    openClientSupportChat() {
      window
        .open(
          "https://tawk.to/chat/6436183d31ebfa0fe7f7c8c4/1gtpktcat",
          "_blank"
        )
        .focus();
    },
    async markNotificationAsRead(notification) {
      try {
        let response = await this.$store.dispatch("MARKNOTIFICATIONASREAD", {
          notificationId: notification._id,
        });
        if (response !== "Success") {
          this.$buefy.dialog.confirm({
            title: "Error",
            message:
              "Hubo un error al marcar la notificación como leida en la base de datos",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message:
            "Hubo un error al marcar la notificación como leida en la base de datos",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    async markAllNotificationAsRead() {
      try {
        let response = await this.$store.dispatch(
          "MARKALLNOTIFICATIONASREAD",
          {}
        );
        if (response !== "Success") {
          this.$buefy.dialog.confirm({
            title: "Error",
            message:
              "Hubo un error al marcar las notificaciones como leidas en la base de datos",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message:
            "Hubo un error al marcar las notificaciones como leida en la base de datos",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
  },
  computed: {
    companInformation() {
      return this.$store.getters.COMPANY_INFORMATION;
    },
    permissions() {
      if (this.$store.getters.USER.permissions) {
        return this.$store.getters.USER.permissions.permissions;
      }
      return this.$store.getters.USER.permissions;
    },
    companyPaymentPlanModules() {
      if (
        this.$store.getters.COMPANY_INFORMATION &&
        this.$store.getters.COMPANY_INFORMATION.paymentPlan
      ) {
        return this.$store.getters.COMPANY_INFORMATION.paymentPlan.modules;
      }
      return null;
    },
    nextPaymetnDate() {
      return this.$store.getters.NEXTPAYMENTDATE;
    },
    activeNav() {
      return this.$store.getters.ACTIVE;
    },
    filteredModels() {
      return this.searchableModels.filter((singleModel) => {
        // Normalize both the model and the search result to ignore accents
        const normalizedModel = this.normalizeString(singleModel);
        const normalizedSearchResult = this.normalizeString(this.searchResult);
        // Perform the filtering based on normalized strings
        return normalizedModel.includes(normalizedSearchResult);
      });
    },
    userInformation() {
      return this.$store.getters.USER;
    },
    notifications() {
      return this.$store.getters.NOTIFICATIONS.map((singleNotification) => {
        const singleNotificationWithProps = { ...singleNotification };

        singleNotificationWithProps.formattedDate = moment(
          singleNotification.date
        ).format("DD-MM-YYYY HH:mm");

        return singleNotificationWithProps;
      });
    },
  },
};
</script>

<style scoped>
@import "../style/Navbar.css";
@import "../style/Global.css";
</style>
